import React from 'react'

import { Alert } from 'packages/common'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { fullName } from 'app/hkhub/store/users'
import { formatReservationDateTimeAttributes } from 'app/hkhub/utils'

import { useUnitVisits } from '../hooks/useUnitVisits'

type TranslationsProps = Pick<
  CreateVisitWarningsProps,
  'currentReservation' | 'otherVisitUser'
>

const useTranslations = (props: TranslationsProps) => {
  const { t } = useI18n()

  const { checkoutDate: checkoutDateStr, checkoutTime: checkoutTimeStr } =
    formatReservationDateTimeAttributes(props.currentReservation)

  const userName = props.otherVisitUser ? fullName(props.otherVisitUser) : ''

  return {
    otherUserHasVisit: t(Slugs.createVisitErrorOtherUserVisit, {
      userName,
    }),
    unitHasUnassignedTodayVisit: t(
      Slugs.createVisitErrorUnitHasUnassignedTodayVisit,
    ),
    unitIsOccupied: t(Slugs.createVisitErrorUnitOccupied, {
      date: checkoutDateStr,
      time: checkoutTimeStr,
    }),
    userHasVisit: t(Slugs.createVisitErrorUserHasVisit),
  }
}

export type CreateVisitWarningsProps = ReturnType<typeof useUnitVisits>

export const CreateVisitWarnings: React.FC<CreateVisitWarningsProps> =
  React.memo(
    ({
      userHasExistingVisit,
      currentReservation,
      otherVisitUser,
      unitHasUnassignedTodayVisit,
    }) => {
      const strings = useTranslations({
        currentReservation,
        otherVisitUser,
      })

      return (
        <>
          {!!userHasExistingVisit && (
            <Alert alertType={'danger'}>{strings.userHasVisit}</Alert>
          )}
          {!!currentReservation && (
            <Alert alertType={'warning'}>{strings.unitIsOccupied}</Alert>
          )}
          {!!otherVisitUser && (
            <Alert alertType={'info'}>{strings.otherUserHasVisit}</Alert>
          )}
          {!!unitHasUnassignedTodayVisit && (
            <Alert alertType={'info'}>
              {strings.unitHasUnassignedTodayVisit}
            </Alert>
          )}
        </>
      )
    },
  )
