import React from 'react'
import { useSelector } from 'react-redux'
import { SingleValue } from 'react-select'

import { Button, Alert, useToast } from 'packages/common'
import { createDateObject, format } from 'packages/utils/dateHelpers'
import { useAsyncFnWithReset } from 'packages/utils/hooks'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { useAppDispatch } from 'app/hkhub/store/hooks'
import { Housekeeper } from 'app/hkhub/store/housekeepers/housekeepers.types'
import { getSortedHks } from 'app/hkhub/store/housekeepers/selectors'
import { ApplicationState } from 'app/hkhub/store/store'
import { Unit } from 'app/hkhub/store/units'
import { createVisit } from 'app/hkhub/store/visits/actions/createVisit'

import { CreateVisitWarnings } from '../CreateVisitWarnings/CreateVisitWarnings'
import { useUnitVisits } from '../hooks/useUnitVisits'
import { PastInspections } from '../PastInspections'
import { UnitSearchSelector } from '../UnitSearchSelector'
import { VisitAssignment } from '../VisitAssignment'

import styles from './CreateVisitModalForm.module.scss'

export enum CreateVisitModalFormTestIds {
  addVisitButton = 'CreateVisitModalForm__addVisitButton',
  cancelButton = 'CreateVisitModalForm__cancelButton',
  container = 'CreateVisitModalForm__container',
  unitSelectContainer = 'CreateVisitModalForm__unitSelectContainer',
}

const useTranslations = () => {
  const { ut } = useI18n()
  return {
    addVisit: ut(Slugs.addVisit),
    cancel: ut(Slugs.cancel),
    date: ut(Slugs.date),
    error: ut(Slugs.error),
    success: ut(Slugs.addVisitSuccessMessage),
    today: ut(Slugs.today),
    unit: ut(Slugs.unit),
  }
}

const getFormattedTodayDate = (): string => {
  const today = createDateObject()
  return format(today, 'LLLL d')
}

export type CreateVisitModalFormProps = {
  onClose: () => void
}

export const CreateVisitModalForm: React.FC<CreateVisitModalFormProps> = ({
  onClose,
}) => {
  const strings = useTranslations()
  const [unit, setUnit] = React.useState<Unit | null>(null)
  const [assignedHousekeeper, setAssignedHousekeeper] =
    React.useState<Housekeeper | null>(null)
  const dispatch = useAppDispatch()
  const { showToast } = useToast()
  const housekeepers = useSelector((state: ApplicationState) =>
    getSortedHks(state?.housekeepers),
  )

  const {
    userHasExistingVisit,
    currentReservation,
    otherVisitUser,
    unitHasUnassignedTodayVisit,
  } = useUnitVisits(unit?.id || '')

  const [createVisitState, createVisitFn] = useAsyncFnWithReset(async () => {
    if (!unit) return

    return dispatch(
      createVisit(
        {
          housekeeperId: assignedHousekeeper?.id,
          unitId: unit.id,
        },
        {
          onSuccess: () => {
            showToast({ message: strings.success })
            onClose()
          },
        },
      ),
    )
  }, [unit, assignedHousekeeper, dispatch, showToast, strings.success, onClose])

  const onClickAddVisit = React.useCallback(() => {
    createVisitFn()
  }, [createVisitFn])

  const onUnitChange = React.useCallback(
    (unit: SingleValue<Unit>) => {
      setUnit(unit)
      setAssignedHousekeeper(
        housekeepers.find(hk => hk.user.id === unit?.managerUserId) || null,
      )
    },
    [housekeepers],
  )

  return (
    <div
      className={styles.container}
      data-testid={CreateVisitModalFormTestIds.container}
    >
      <div className={styles.contentContainer}>
        <h3 className={styles.modalTitle}>{strings.addVisit}</h3>
        <div className={styles.todayDateContainer}>
          <label className={styles.label}>{strings.date}</label>
          <div className={styles.todayDate}>
            {`${strings.today}, ${getFormattedTodayDate()}`}
          </div>
        </div>
        <div
          className={styles.unitSelectContainer}
          data-testid={CreateVisitModalFormTestIds.unitSelectContainer}
        >
          <label className={styles.label}>{strings.unit}</label>
          <UnitSearchSelector onUnitChange={onUnitChange} />
        </div>
        {!!unit && (
          <>
            <VisitAssignment
              housekeeper={assignedHousekeeper}
              setHousekeeper={setAssignedHousekeeper}
              options={housekeepers}
            />
            <CreateVisitWarnings
              userHasExistingVisit={userHasExistingVisit}
              currentReservation={currentReservation}
              otherVisitUser={otherVisitUser}
              unitHasUnassignedTodayVisit={unitHasUnassignedTodayVisit}
            />
            <PastInspections unit={unit} />
          </>
        )}
      </div>
      {!!createVisitState.error && (
        <Alert alertType={'danger'}>{strings.error}</Alert>
      )}
      <div className={styles.actionsContainer}>
        <Button
          buttonType="secondary"
          onClick={onClose}
          block={true}
          dataTestId={CreateVisitModalFormTestIds.cancelButton}
          isLoading={createVisitState.loading}
        >
          {strings.cancel}
        </Button>
        <Button
          buttonType="primary"
          block={true}
          onClick={onClickAddVisit}
          disabled={
            !unit ||
            userHasExistingVisit ||
            unitHasUnassignedTodayVisit ||
            !!otherVisitUser
          }
          dataTestId={CreateVisitModalFormTestIds.addVisitButton}
          isLoading={createVisitState.loading}
        >
          {strings.addVisit}
        </Button>
      </div>
    </div>
  )
}
