import { useSelector } from 'react-redux'

import {
  format,
  createDateObject,
  DateFormat,
} from 'packages/utils/dateHelpers'

import { Reservation } from 'app/hkhub/store/reservations'
import { getReservationById } from 'app/hkhub/store/reservations/selectors'
import { ApplicationState } from 'app/hkhub/store/store'
import { getUnitById } from 'app/hkhub/store/units/selectors'
import { User } from 'app/hkhub/store/users'
import { getUserByHkId, getUserHkId } from 'app/hkhub/store/users/selectors'
import { getVisitsByUnitId } from 'app/hkhub/store/visits/selectors'

type UseUnitVisits = {
  currentReservation?: Reservation
  otherVisitUser?: User
  unitHasUnassignedTodayVisit: boolean
  userHasExistingVisit: boolean
}

export const useUnitVisits = (unitId: string): UseUnitVisits => {
  const housekeeperId = useSelector(getUserHkId) || ''

  const unit = useSelector((state: ApplicationState) =>
    getUnitById(state?.units, unitId),
  )

  const currentReservation = useSelector((state: ApplicationState) =>
    getReservationById(state, unit?.currentReservationId || ''),
  )

  let unitHasUnassignedTodayVisit = false
  let userHasExistingVisit = false
  let otherVisitHkId: string | undefined

  const openVisitsForUnit = useSelector((state: ApplicationState) =>
    getVisitsByUnitId(state, unit?.id || ''),
  )

  const todayVisitForUnit = openVisitsForUnit.find(
    visit =>
      visit.effectiveDate ===
      format(createDateObject(), DateFormat.ApiUtcShort),
  )

  //-----------------------------------------------------------
  // Find:
  // - open unassigned visit for this unit
  // - open visits for this unit assigned to the current HK/User
  // - open visits for this unit assigned to other HK/Users
  //-----------------------------------------------------------

  if (todayVisitForUnit) {
    // unit has unassigned visit
    if (todayVisitForUnit.assignedHkIds.length === 0) {
      unitHasUnassignedTodayVisit = true
    } else {
      // current user has existing visit
      if (todayVisitForUnit.assignedHkIds.includes(housekeeperId)) {
        userHasExistingVisit = true
      } else {
        // other housekeeper that has visit for this unit
        otherVisitHkId = todayVisitForUnit.assignedHkIds[0]
      }
    }
  }

  const otherVisitUser = useSelector((state: ApplicationState) =>
    getUserByHkId(state, otherVisitHkId || ''),
  )

  return {
    currentReservation,
    otherVisitUser,
    unitHasUnassignedTodayVisit,
    userHasExistingVisit,
  }
}
