import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

import { IconName, SvgIcon } from 'packages/iconic'
import { centerWithFlex, colors, text, largeScreen } from 'packages/styles'
import {
  createDateString,
  DateFormat,
  formatLocalized,
} from 'packages/utils/dateHelpers'

import { Slugs, useI18n } from 'app/hkhub/i18n'
import { Unit } from 'app/hkhub/store/units'

const St = {
  IconWrapper: styled.div`
    ${centerWithFlex};
    grid-gap: 8px;
  `,
  InspectionDetail: styled.div`
    align-items: center;
    background-color: ${colors.midnight4};
    border: 1px solid ${colors.midnight20};
    border-radius: 3px;
    display: flex;
    height: 48px;
    justify-content: space-between;
    padding: 8px;
    gap: 8px;
  `,
  InspectionType: styled.div`
    ${text.bodyBoldTiny};
    padding-top: 1px; // for better visual centering with icon
    text-transform: uppercase;
  `,
  LoaderWrapper: styled.div`
    height: 20px;
    position: relative;
  `,
  LoadingText: styled.div`
    ${text.bodyRegularSmall};
  `,
  PastInspections: styled.div`
    margin-top: 24px;
  `,
  SectionHeader: styled.div`
    ${text.fieldLabelMobile};
    margin-bottom: 8px;
  `,
  Subtitle: styled.div`
    ${text.bodyRegularSmall};
    color: ${colors.dusk80};
    margin: 16px 0;
  `,
  Timestamp: styled.div`
    ${text.bodyRegularTiny};
    font-weight: 700;
    display: flex;
    flex-direction: column;

    & span {
      font-weight: 400;
    }

    ${largeScreen(
      css`
        flex-direction: row;
      `,
    )};
  `,
}

export enum PastInspectionsTestIds {
  container = 'PastInspections__container',
}

const useTranslations = () => {
  const { tp, ut } = useI18n()
  return {
    lastCompleted: ut(Slugs.lastCompleted),
    noActivity: ut(Slugs.noActivity),
    subtitle: ut(Slugs.addVisitInspectionSubtitle),
    title: tp(Slugs.inspection, 2, false),
    type: ut(Slugs.routine),
  }
}

// minor helper hook to deal with the multiple possible states of "last completed" text
const useParseDateText = (unit: Unit) => {
  const strings = useTranslations()

  const { lastInspectionCompletedAt } = unit?.statistics || {}
  const date =
    lastInspectionCompletedAt && createDateString(lastInspectionCompletedAt)

  return date
    ? formatLocalized(date, DateFormat.MonthShortDateAndYear)
    : strings.noActivity
}

export type PastInspectionsProps = {
  unit: Unit
}

export const PastInspections: React.FC<PastInspectionsProps> = React.memo(
  ({ unit }) => {
    const strings = useTranslations()
    const lastCompletedText = useParseDateText(unit)

    return (
      <St.PastInspections data-testid={PastInspectionsTestIds.container}>
        <St.SectionHeader>{strings.title}</St.SectionHeader>
        <St.Subtitle>{strings.subtitle}</St.Subtitle>
        <St.InspectionDetail>
          <St.IconWrapper>
            <SvgIcon icon={IconName.homePast} size={15} />
            <St.InspectionType>{strings.type}</St.InspectionType>
          </St.IconWrapper>
          <St.Timestamp>
            {strings.lastCompleted}:&nbsp;<span>{lastCompletedText}</span>
          </St.Timestamp>
        </St.InspectionDetail>
      </St.PastInspections>
    )
  },
)
