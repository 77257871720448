import { noop } from 'lodash/fp'
import { createAsyncAction } from 'typesafe-actions'

import { TaskServiceType } from 'packages/grimoire'
import { ReduxActionCallbacks } from 'packages/grimoire/src/utils'
import {
  format,
  createDateObject,
  DateFormat,
} from 'packages/utils/dateHelpers'
import {
  RequestConfig,
  RequestOptions,
  makeRelationship,
} from 'packages/utils/store'

import { NormalizedTasksApiResponse } from 'app/hkhub/store/tasks'
import { tasksService } from 'app/hkhub/store/tasks/tasks.service'
import { setVisitDrawerState } from 'app/hkhub/store/ui/actions'

import { CreateVisitPostData, VisitsActionTypes } from '../visits.types'

export const createVisitAction = createAsyncAction(
  VisitsActionTypes.CREATE_VISIT,
  VisitsActionTypes.CREATE_VISIT_SUCCESS,
  VisitsActionTypes.CREATE_VISIT_FAILURE,
)<
  RequestConfig<NormalizedTasksApiResponse>,
  NormalizedTasksApiResponse,
  Error
>()

export const getParams = (postData: CreateVisitPostData): RequestOptions => {
  const options = {
    data: {
      attributes: {
        effective_date: format(createDateObject(), DateFormat.ApiUtcShort),
        job_type: ['inspection'],
        service_type: TaskServiceType.adhoc,
      },
      relationships: {
        unit: makeRelationship('unit', postData.unitId),
      },
      type: 'task',
    },
  }

  if (postData.housekeeperId) {
    options.data.relationships['assigned_housekeepers'] = {
      data: [{ id: postData.housekeeperId, type: 'housekeeper' }],
    }
  }

  return options
}

export const createVisit =
  (postData: CreateVisitPostData, callbacks: ReduxActionCallbacks = {}) =>
  async dispatch => {
    const { onError = noop, onSuccess = noop } = callbacks
    try {
      const params = getParams(postData)
      const request = tasksService.createTask.bind(null, params)
      const result = await dispatch(createVisitAction.request({ request }))
      dispatch(createVisitAction.success(result))

      const newVisitId = Object.keys(result.normalized.task)[0]

      // once we have a valid visit ID, open the drawer for said visit
      // the timeout here is purely for aesthetic reasons,
      // as this will give the "create visit" drawer time to close before we open the next one.
      setTimeout(() => {
        dispatch(
          setVisitDrawerState({
            isOpen: true,
            visitId: newVisitId,
          }),
        )
      }, 250)

      onSuccess(newVisitId)
      return result.normalized
    } catch (error) {
      dispatch(createVisitAction.failure(error))
      onError()
      throw error
    }
  }
